//
// layouts.scss
//

// scrollable-layout
.myCustomToast .Toastify__toast {
    border-radius: 8px;
}

.Toastify__toast--success {
    background-color: #6f7975 !important;
}

.Toastify__toast--error {
    background-color: #ff5c75 !important;
}

.chosen {
    border: 2px solid #43d39e !important;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #f7f7f7;
}

.dropzone:focus {
    border: 2px solid red !important;
}

@include media-breakpoint-up(xl) {
    .scrollable-layout {
        padding-bottom: 0;
        #wrapper {
            display: flex;
        }

        .left-side-menu {
            position: relative;
            min-width: 250px;
            min-height: 100vh;
        }

        .navbar-custom {
            position: absolute;
        }

        .content-page {
            margin-left: 0;
            width: 100%;
            padding-bottom: 60px;
        }

        &.left-side-menu-condensed {
            .left-side-menu {
                min-width: 70px;
            }
            .content-page {
                margin-left: 0px !important;
            }
        }
    }
}

// boxed-layout

.boxed-layout {
    background: darken($body-bg, 2%);

    #wrapper {
        max-width: $boxed-layout-width;
        margin: 0 auto;
        box-shadow: $box-shadow;
        background: $body-bg;
    }

    .navbar-custom {
        max-width: $boxed-layout-width;
        margin: 0 auto;
    }

    .footer {
        margin: 0 auto;
        max-width: calc(#{$boxed-layout-width} - #{$leftbar-width});
        background: $body-bg;
    }

    &.left-side-menu-condensed {
        min-height: auto;
        .footer {
            max-width: calc(#{$boxed-layout-width} - #{$leftbar-width-collapsed});
        }
    }
}

.filter-drawer {
    //
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .filter-drawer-content {
        height: 80%;
        width: 95%;
    }
    .subtitle {
        margin-left: 10px;
        font-size: 1rem;
        font-weight: bold;
        color: $gray-600;
    }
    .filter-drawer-form-container {
        // background: red;
        height: 80%;
        width: 95%;
    }
    .filter-drawer-period-container {
        height: 40%;
        width: 100%;
        position: absolute;

        // top: 400px;
        label {
            width: auto;
            min-width: 45px;
            margin-top: 5px;
            margin-left: 20px;
        }

        .filter-drawer-select-container-box-buttons {
            height: 60px;
            // background: blue;
            margin: 30px 0 0 20px;

            @media (max-width: 730px) {
                margin: 30px 0 0 10px;
            }

            .filter-drawer-buttons {
                width: 95px;
                height: 30px;
                margin-right: 20px;
                color: $white;
                background: $blue;
                border: none;
                border-radius: 5px;
            }
        }

        .filter-drawer-agent-selection-container {
            margin-top: 30px;
            height: 80px;
            width: 100%;
        }
    }

    .filter-drawer-select-container {
        display: flex;
        margin-left: 10px;
        margin-top: 5px;
        align-items: center;

        flex-direction: row;
        // background: blue;
        label {
            width: auto;
            min-width: 70px;
            margin-top: 5px;
        }

        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            border: none;
            background-color: $white;
            margin-left: 15px;
            margin-top: 2px;
            width: 85%;
            // font-weight: bold;
            // color: $gray-600;
        }

        .select {
            align-items: center;
            justify-content: center;
            border: 2px solid $gray-400;
            height: 30px;
            width: 140px;

            border-radius: 5px;

            span {
                color: $black;
                margin-left: -15px;
            }
        }
    }

    .filter-drawer-calendar-container {
        .filter-drawer-calendar-box {
            width: 8rem;
            text-align: center;
            border: 2px solid $gray-400;
            // margin-right: 10px;
            margin-left: 10px;
            width: 210px;

            border-radius: 5px;
            height: 30px;

            font-weight: bold;
            color: $gray-600;
            font-size: 0.7rem;
        }
    }

    .filter-drawer-agent-selection-modal-container{
        height:400px;
        width: 100px;
    }
}
