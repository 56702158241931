//
// forms.scss
//

// Form-control light
.form-control-light {
    background-color: $gray-100;
    border-color: $gray-100;
}

// Form elements (Color and Range)
input.form-control[type='color'],
input.form-control[type='range'] {
    min-height: 39px;
}

// Custom select
.custom-select.is-invalid,
.form-control.is-invalid,
.custom-select:invalid,
.form-control:invalid,
.custom-select.is-valid,
.form-control.is-valid,
.custom-select:valid,
.form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}

// Comment box
.comment-area-box {
    .form-control {
        border-color: $gray-300;
        border-radius: $input-border-radius $input-border-radius 0 0;
    }
    .comment-area-btn {
        background-color: $gray-100;
        padding: 10px;
        border: 1px solid $gray-300;
        border-top: none;
        border-radius: 0 0 $input-border-radius $input-border-radius;
    }
}

// Input
input {
    &:focus {
        outline: none;
    }
}
